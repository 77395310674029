import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: ['/home'],
    name: 'home',
    component: () => import(/* webpackChunkName: "TripInputs" */ '../views/TripInputs.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/',
    alias: ['/home'],
    name: 'home',
    component: () => import(/* webpackChunkName: "About" */ '../views/TripInputs.vue')
  },
  {
    name: 'trip-planner-inputs-empty',
    path: '/plan/',
    alias: ['/plan-trip', '/trip-planner', '/trip-options'],
    component: () => import(/* webpackChunkName: "TripInputs" */ '../views/TripInputs.vue')
  },
  {
    name: 'trip-planner-inputs-start-ready',
    path: '/plan/:startName/:startLat/:startLng',
    props: true,
    component: () => import(/* webpackChunkName: "TripInputs" */ '../views/TripInputs.vue')
  },
  {
    name: 'trip-planner-inputs-finish-ready',
    path: '/plan/finish/:finishName/:finishLat/:finishLng',
    props: true,
    component: () => import(/* webpackChunkName: "TripInputs" */ '../views/TripInputs.vue')
  },
  {
    name: 'trip-planner-inputs-ready',
    path: '/plan/:startName/:startLat/:startLng/:finishName/:finishLat/:finishLng/:date/:time/:deparr/:modes',
    alias: '/plan/:startName/:startLat/:startLng/:finishName/:finishLat/:finishLng/now/:deparr',
    props: true,
    component: () => import(/* webpackChunkName: "TripInputs" */ '../views/TripInputs.vue')
  },
  {
    name: 'trip-planner-inputs-ready-plan',
    path: '/plan/:startName/:startLat/:startLng/:finishName/:finishLat/:finishLng/:date/:time/:deparr/:modes/plan/:tripIndex?',
    props: true,
    component: () => import(/* webpackChunkName: "TripInputs" */ '../views/TripInputs.vue')
  },
  {
    name: 'trip-planner-trips-results',
    path: '/plan/:startName/:startLat/:startLng/:finishName/:finishLat/:finishLng/:date/:time/:deparr/:modes/results/:tripIndex',
    props: true,
    component: () => import(/* webpackChunkName: "TripInputs" */ '../views/TripInputs.vue')
  },
  {
    name: 'trip-planner-trip-details',
    path: '/plan/:startName/:startLat/:startLng/:finishName/:finishLat/:finishLng/:date/:time/:deparr/:modes/results/:mode/:tripIndex',
    props: true,
    component: () => import(/* webpackChunkName: "TripInputs" */ '../views/TripInputs.vue')
  },
  {
    name: 'admin-feedback',
    path: '/admin/feedback',
    props: true,
    components: {
      default: () => import(/* webpackChunkName: "AdminFeedback" */ '../views/AdminFeedback.vue'),
      user: () => import(/* webpackChunkName: "AdminFeedback" */ '../views/AdminFeedback.vue'),
      admin: () => import(/* webpackChunkName: "AdminFeedback" */ '../views/AdminFeedback.vue')
    }
  },
  {
    name: 'admin-users',
    path: '/admin/users',
    props: true,
    components: {
      default: () => import(/* webpackChunkName: "AdminUsers" */ '../views/AdminUsers.vue'),
      user: () => import(/* webpackChunkName: "AdminUsers" */ '../views/AdminUsers.vue'),
      admin: () => import(/* webpackChunkName: "AdminUsers" */ '../views/AdminUsers.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
