import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import tripPlanner from './modules/trip-planner'
import map from './modules/map'
import realtime from './modules/realtime'
import geolocation from './modules/geolocation'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const gaID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID || ''

const store = new Vuex.Store({
  modules: {
    'trip-planner': tripPlanner,
    'map': map,
    'geolocation': geolocation,
    'realtime': realtime,
    // schedules,
    // nearby,
    // weather,
  },
  strict: debug
})

// update the state according to the route params
router.beforeEach((to, from, next) => {
  console.log("from, to", from.name, to.name)

  // add to Google Analytics (to collect stats for SPA)
  window.gtag('config', gaID, { 'page_path': location.pathname });

  if (to.params.startName && to.params.startLat && to.params.startLng) {
    store.commit('trip-planner/SET_INPUTS_ORIGIN', {
      name: to.params.startName,
      lat: to.params.startLat,
      lng: to.params.startLng,
    })
  }
  if (to.params.finishName && to.params.finishLat && to.params.finishLng) {
    store.commit('trip-planner/SET_INPUTS_DESTINATION', {
      name: to.params.finishName,
      lat: to.params.finishLat,
      lng: to.params.finishLng,
    })
  }
  if (to.params.date) {
    store.commit('trip-planner/SET_INPUTS_WHEN_DATE', to.params.date)
  }
  if (to.params.time) {
    store.commit('trip-planner/SET_INPUTS_WHEN_TIME', to.params.time)
  }
  if (to.params.deparr) {
    store.commit('trip-planner/SET_INPUTS_WHEN_TYPE', to.params.deparr)
  }
  if (to.query.modes) {
    store.commit('trip-planner/SET_INPUTS_MODES', to.query.modes.split(","))
  }
  if (to.params.tripIndex) {
    store.commit('trip-planner/SET_TRIP_INDEX', to.params.tripIndex)
  }
  if (to.name !== 'trip-planner-trip-details') {
    store.commit('map/SET_PREVIEW_ACTIVE', false)
  }

  // always clear realtime call on new route
  // store.commit('realtime/CLEAR_REALTIME')

  store.commit('trip-planner/SET_STAGE', 'inputs')

  if (to.name.indexOf('trip-planner-inputs') > -1) {
    let params = { ...to.params }
    let dateNow = new Date().toISOString().substr(0,10)
    let timeNow = new Date().toISOString().substr(11,5)
    if (timeNow > params.time && dateNow === params.date) {
      store.commit('trip-planner/SET_INPUTS_WHEN_TIME', timeNow)
    }
    if (dateNow > params.date) {
      store.commit('trip-planner/SET_INPUTS_WHEN_TIME', dateNow)
    }
  }

  if (to.name === 'trip-planner-inputs-ready-plan') {
    store.commit('trip-planner/SET_RESPONSE_RECEIVED', false)
  }

  if ((to.name === 'trip-planner-inputs-ready-plan'
      || to.name === 'trip-planner-trips-results'
      || to.name === 'trip-planner-trip-details')
      && !store.state['trip-planner'].responseReceived && !store.state['trip-planner'].loading) {
    console.log("***")
  // if (to.name === 'trip-planner-inputs-ready-plan' || ((to.name === 'trip-planner-trips-results' || to.name === 'trip-planner-trip-details') && !store.state['trip-planner'].responseReceived )) {
    let params = { ...to.params }
    let mode = params.mode
    let routeIndex = params.routeIndex
    if (mode) { delete params.mode }
    if (routeIndex) { delete params.routeIndex }
    store.commit('trip-planner/SET_STAGE', 'results')
    store.commit('trip-planner/SET_LOADING', true)
    store.commit('trip-planner/SET_RESULTS', [])
    store.dispatch('trip-planner/planJourney', { ...params, ...to.query })
      .then(() => {
        store.commit('trip-planner/SET_LOADING', false)
        store.commit('trip-planner/SET_RESPONSE_RECEIVED', true)
        if (from.name === to.name) {
          return
        }
        // if (to.name === 'trip-planner-trip-details') {
        //   router.push({ name: 'trip-planner-trip-details', params: to.params })
        // } else {
        //   console.log("go to RESULTS***", from.name, to.name)
        //   router.push({ name: 'trip-planner-trips-results', params: to.params })
        // }
      })
      .catch(err => {
        console.log("@@@ERROR---", err)
        store.commit('trip-planner/SET_ERROR', err)
        store.commit('trip-planner/SET_LOADING', false)
        store.commit('trip-planner/SET_RESPONSE_RECEIVED', true)
      })
      // .then(() => {
      //   store.commit('trip-planner/SET_LOADING', false)
      //   store.commit('trip-planner/SET_RESPONSE_RECEIVED', true)
      // })
  }

  next()
})

export default store