<template>
  <div class="welcome-screen">

    <v-card
      class="d-flex justify-center mb-6"
      color="transparent"
      flat
      tile>
      <v-card
        style="overflow: auto; max-height: 100vh;"
        max-width="400"
        class="pa-4">
        <v-card-text class="my-0 py-0">
          <v-img
            class="white--text align-end"
            height="84px"
            width="100%"
            src="@/assets/logo-routemasters.jpg"
          ></v-img>
        </v-card-text>
        <v-card-title class="text-h6">
          Welcome to LagosGO
        </v-card-title>
        <v-card-subtitle Aclass="white--text">The new trip planner for Lagos! To help test and stay up to date on the development of please sign up! </v-card-subtitle>
        <v-card-text>
          <v-form
            ref="form"
            v-model="signupValid"
          >
            <v-text-field
              v-model="fullname"
              :rules="nameRules"
              label="Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
            <v-text-field
              v-model="telephone"
              label="Telephone"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-text>
          <v-alert v-if="ajaxStatus === 'success'" type="success">Successfully registered!</v-alert>
          <v-alert v-else-if="ajaxStatus === 'error'" type="error">{{ ajaxErrorMsg }}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="!signupValid"
            :loadinga="ajaxStatus === 'loading'"
            color="primary"
            class="my-0"
            @click="signup"
          >
            Sign up
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="my-0"
            text
            title="NO thanks, just take me to the trip planner!"
            @click="dismissSignup"
          >
            No thanks
          </v-btn>
          <br><br>
        </v-card-actions>
      </v-card>
    </v-card>

  </div>
</template>

<script>
  import Cookies from "js-cookie";
  import { signupUser } from '@/api'

  export default {
    data () {
      return {
        ajaxStatus: '',
        ajaxErrorMsg: '',
        signupValid: true,
        fullname: '',
        telephone: '',
        nameRules: [
          v => !!v || 'Name is required',
          v => (v && v.length <= 200) || 'Name must be less than 200 characters',
        ],
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      }
    },
    methods: {
      async dismissSignup () {
        Cookies.set("signup-complete", true, { expires: 1 }); // expires after 1 day
        // this.signupCompleted = true;
        this.$emit('signupHasCompleted', true)
      },
      async signup () {
        console.log("signup -> signup")
        this.ajaxStatus = 'loading'
        const data = {
          full_name: this.fullname,
          telephone: this.telephone,
          email: this.email
        }
        try {
          const response = await signupUser(data)
          this.ajaxStatus = 'success'
          Cookies.set("signup-complete", true, { expires: 365 }); // expires after 1 year
          console.log("signup -> response", response)
          setTimeout(() => this.$emit('signupHasCompleted', true), 1500)

        } catch (error) {
          this.ajaxStatus = 'error'
          this.ajaxErrorMsg = error
          console.log("signup -> error", error)
        }
        setTimeout(() => this.ajaxStatus = '', 2000)
      }
    },
  }
</script>

<style lang="scss" scoped>
.welcome-screen {
  margin: auto;
  background-color: rgb(0, 0, 0, 0.2);
  position: fixed;
  z-index: 9999;
  padding-top: 100px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 760px) {
  .welcome-screen {
    padding-top: 10px;
  }
}


@media (max-height: 650px) {
  .welcome-screen {
    padding-top: 10px;
  }
}
</style>