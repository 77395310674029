<template>
  <div>
    <!-- <v-radio-group
      v-model="activeTiles"
      row>
      <v-radio
        label="OpenStreetMap map tiles"
        value="osm"
      ></v-radio>
      <v-radio
        label="Google map tiles"
        value="google"
      ></v-radio>
    </v-radio-group> -->
    <l-map
      ref="mini-map"
      class="mini-map"
      :zoom="startLatLng.lat ? startZoom : $store.state['map'].startZoom"
      :center="startLatLng"
      :options="{ zoomControl: false }"
      @click="mapClicked">
      <l-control-scale
        position="bottomleft"
        :imperial="true"
        :metric="false">
      </l-control-scale>
      <l-control-zoom position="topright"></l-control-zoom>
      <l-tile-layer
        v-if="activeTiles === 'google'"
        :url="tileUrlGoogle"
        :subdomains="['mt0','mt1','mt2','mt3']"></l-tile-layer>
      <l-tile-layer
        v-else
        :url="tileUrl"></l-tile-layer>
      <l-marker
        :lat-lng="stopLatLngNew"
        @dragend="markerDragged"
        :draggable="true"
      />
      <l-circle
        :radius="10"
        color="#ff2211"
        :lat-lng="[stopLatLng.lat, stopLatLng.lng]"
      />
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LControlScale, LControlZoom, LCircle, LMarker } from 'vue2-leaflet';

  export default {
    components: {
      LMap, LTileLayer, LControlScale, LControlZoom, LCircle, LMarker
    },
    props: {
      startZoom: {
        type: Number,
        default: 16
      },
      stopLatLng: {
        type: Object,
        default: () => ({ lat: 0, lng: 0})
      },
      startLatLng: {
        type: Object,
        default: () => ({ lat: 0, lng: 0})
      },
    },
    data() {
      return {
        activeTiles: 'osm',
        stopLatLngNew: { ...this.stopLatLng },
        tileUrl: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
        tileUrlGoogle: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
      }
    },
    methods: {
      markerDragged (e) {
        console.log("markerDragged -> markerDragged")
        this.stopLatLngNew = e.target.getLatLng()
        this.$emit("stopLocationUpdated", this.stopLatLngNew)
      },
      mapClicked (e) {
        console.log("mapClicked -> e", e.latlng)
        if (this.stopLatLng.lat !== 0) {
          return
        }
        this.stopLatLngNew = { lat: e.latlng.lat, lng: e.latlng.lng }
        this.$emit("stopAdded", this.stopLatLngNew)
        console.log("mapClicked -> stopAdded", this.stopLatLngNew)
      }
    },
  }
</script>

<style lang="scss" scoped>
.mini-map {
  min-height: 200px;
}
</style>