<template>
  <div style="position: absolute;z-index: 9999;">
    <v-dialog
      v-model="open"
      max-width="500">
      <v-card>
        <v-card-title class="headline">
          Submit Feedback
        </v-card-title>

        <template v-if="stage === 'start'">
        <v-card-text>
          Please let us know what type feedback you want to report?
        </v-card-text>
        <v-list dense>
          <v-list-item-group
            vmodel="selectedFeedbackType"
            color="primary">
            <v-list-item
              v-for="(item, i) in feedbackTypes"
              :key="i"
              @click="selectFeedbackType(item.type)">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        </template>

        <!-- Stop selection screen -->
        <template v-else-if="stage === 'stop-list'">
        <v-card-text>
          Please select the relevant stop.
        </v-card-text>
        <feedback-stop-list
          :listType="selectedFeedbackType"
          :legs="transitLegs"
          @selectstop="selectStopAndRoute"></feedback-stop-list>
        </template>

        <!-- Route selection screen -->
        <template v-else-if="stage === 'route-list'">
        <v-card-text>
          Please select the relevant route.
        </v-card-text>
        <v-list dense>
          <v-list-item-group
            v-model="selectedFeedbackRoute"
            color="primary">
          <template>
            <div
              v-for="(leg, index) in transitLegs"
              :key="leg.tripId + index"
                @click="selectRoute(index, leg.routeId)">
              <v-list-item>
                <v-list-item-icon style="margin-left:10px;">
                  <v-icon>mdi-bus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                  <span class="routeid">{{ leg.routeId }}</span> {{ leg.routeLongName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
          </v-list-item-group>
        </v-list>
        </template>

        <!-- Stop name update -->
        <v-card-text v-else-if="stage === 'stop-name'">
          <p>Please correct the stop name</p>
          <v-text-field
            label="Old Stop Name"
            readonly
            disabled
            :value="selectedFeedbackStop ? selectedFeedbackStop.name : '-'"
          ></v-text-field>
          <v-text-field
            label="New Stop Name"
            placeholder="Enter new stop name"
            v-model="newStopName"
          ></v-text-field>
        </v-card-text>

        <!-- Stop location update -->
        <v-card-text v-else-if="stage === 'stop-location'">
          <p>Please correct the stop location by dragging the marker</p>
          <stop-location-map
            :stopLatLng="{ lat: selectedFeedbackStop.lat, lng: selectedFeedbackStop.lon }"
            :startLatLng="{ lat: selectedFeedbackStop.lat, lng: selectedFeedbackStop.lon }"
            @stopLocationUpdated="updateStopLocation"
            />
          <v-text-field
            label="Old Stop Location"
            class="mt-3"
            readonly
            disabled
            :value="selectedFeedbackStop ? selectedFeedbackStop.lat + ',' + selectedFeedbackStop.lon : '-'"
          ></v-text-field>
          <v-text-field
            label="New Stop Location"
            placeholder="Enter new stop name"
            :value="newStopLocation"
          ></v-text-field>
        </v-card-text>

        <!-- Stop location update -->
        <v-card-text v-else-if="stage === 'missing-stop'">
          <p>Please tap on map (then drag if needed) to set the location and enter the new stop name</p>
          <stop-location-map
            @stopAdded="newStopAdded"
            :startLatLng="{ lat: selectedFeedbackStop.lat, lng: selectedFeedbackStop.lon }"
            @stopLocationUpdated="updateStopLocation"
            />
          <v-text-field
            class="mt-3"
            readonly
            disabled
            label="Stop Location"
            :value="newStopLocation"
          ></v-text-field>
          <v-text-field
            label="Stop Name"
            class="mt-3"
            v-model="newStopName"
          ></v-text-field>
        </v-card-text>

        <!-- Stop not in use -->
        <v-card-text v-else-if="stage === 'redundant-stop'">
          <p>Please provide clear explanation of the why the stop is not use.</p>
          <v-textarea
            outlined
            label="Comments"
            class="mt-3"
            v-model="comments"
          ></v-textarea>
          <v-text-field
            label="Stop Name"
            readonly
            disabled
            class="mt-3"
            v-model="selectedFeedbackStop.name"
          ></v-text-field>
        </v-card-text>

        <!-- Stop location update -->
        <v-card-text v-else-if="stage === 'incorrect-fare'">
          <p>Please provide the correct fare (to the best of your knowledge) for both the <strong>leg</strong> of this trip and the <strong>full</strong> trip. Please add any additional comments (optional).</p>
          <v-text-field
            class="mt-3"
            :label="'Full trip Fare: ' + transitLegs[selectedTransitLegIndex].routeLongName"
            type="number"
            prefix="₦"
            v-model="fullTripFare"
          ></v-text-field>
          <v-text-field
            class="mt-3"
            :label="'Leg Fare: ' + transitLegs[selectedTransitLegIndex].from.name + '-' + transitLegs[selectedTransitLegIndex].to.name"
            type="number"
            prefix="₦"
            v-model="legFare"
          ></v-text-field>
          <v-text-field
            label="Comments"
            class="mt-3"
            v-model="comments"
          ></v-text-field>
        </v-card-text>

        <!-- Stop location update -->
        <v-card-text v-else-if="stage === 'other'">
          <p>Please provide clear explanation of the problem you'd like to report:</p>
          <v-textarea
            outlined
            label="Comments"
            class="mt-3"
            v-model="comments"
          ></v-textarea>
        </v-card-text>

        <v-card-text>
          <v-alert v-if="ajaxStatus === 'success'" type="success">Successfully submitted feedback!</v-alert>
          <v-alert v-else-if="ajaxStatus === 'error'" type="error">{{ ajaxErrorMsg }}</v-alert>
        </v-card-text>

        <!-- action buttons -->
        <v-card-actions v-if="stage !== 'start' && stage !== 'stop-list' && stage !== 'route-list'">
          <v-btn
            v-if="this.$store.state['trip-planner'].tripIndex > -1"
            color="green darken-1"
            text
            @click="goBack">
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            Back
          </v-btn>
          <v-btn
            v-else
            color="red darken-1"
            text
            @click="closeForm">
            <v-icon class="mr-2">mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="saveFeedback">
          <v-icon class="mr-2">mdi-content-save</v-icon>
            Save
          </v-btn>
        </v-card-actions>
        <br>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  // import axios from 'axios'
  // import html2canvas from 'html2canvas';
  // import L from 'leaflet';
  // import { latLngBounds } from "leaflet";
  // import polyline from  'google-polyline'
  // import { LMap, LTileLayer, LControlScale, LControlZoom, LCircle, LMarker } from 'vue2-leaflet';
  import { submitFeedback } from '@/api'
  import FeedbackStopList from '@/components/FeedbackStopList.vue'
  import StopLocationMap from '@/components/StopLocationMap.vue'
  import { setTimeout } from 'timers';

  // let mapObjectRef, popup
  export default {
    props: {
      openForm: {
        type: Boolean,
        default: false
      },
    },
    components: {
      FeedbackStopList, StopLocationMap
    },
    data () {
      return {
        ajaxStatus: '',
        ajaxErrorMsg: '',
        open: false,
        stage: 'start',
        stages: [
          'start',
          ['stop-list', 'route-list'],
          ['stop-name', 'stop-location', 'stop-location'],
        ],
        selectedFeedbackType: '',
        selectedFeedbackStop: '',
        selectedFeedbackLeg: '',
        selectedFeedbackRoute: '',
        selectedTransitLegIndex: 0,
        selectedFeedbackStopNewLocation: '',
        fullTripFare: '',
        legFare: '',
        correctFare: '',
        comments: '',
        newStopLocation: '',
        newStopName: '',
        tileUrl: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
        feedbackTypes: [
          { icon: 'mdi-map-marker-alert', text: 'Incorrect stop name?', type: 'stop-name' },
          { icon: 'mdi-map-marker-alert', text: 'Incorrect stop location?', type: 'stop-location' },
          { icon: 'mdi-map-marker-alert', text: 'Missing stop?', type: 'missing-stop' },
          { icon: 'mdi-map-marker-alert', text: 'Stop not in use?', type: 'redundant-stop' },
          { icon: 'mdi-cash-multiple', text: 'Incorrect fare?', type: 'incorrect-fare' },
          // { icon: 'mdi-sign-direction', text: 'Incorrect bus route?', type: 'incorrect-route' },
          { icon: 'mdi-comment-question', text: 'Something else?', type: 'something-else' },
        ],
        legActive: []
      }
    },
    computed: {
      tripIndex () {
        return this.$store.state['trip-planner'].tripIndex
      },
      transitLegs () {
        if (this.$store.state['trip-planner'].results.length && this.tripIndex > -1) {
          return this.$store.state['trip-planner'].results[this.tripIndex].legs.filter(x => x.mode !== 'WALK')
        }
        return []
      }
    },
    methods: {
      closeForm () {
        return this.$store.commit('trip-planner/SET_OPEN_FEEDBACK', false)
      },
      goBack () {
        this.stage = 'start'
        this.ajaxStatus = ''
      },
      selectFeedbackType (type) {
        this.selectedFeedbackType = type
        this.stage = 'stop-list'
        this.correctFare =  ''
        this.comments =  ''
        this.newStopLocation =  ''
        this.newStopName =  ''
        if (type === 'other' || type === 'something-else') {
          this.stage = 'other'
        } else if (type === 'incorrect-fare' || type === 'incorrect-route') {
          this.stage = 'route-list'
        }
      },
      selectStopAndRoute (stop, leg, listType) {
        console.log("***selectStopAndRoute -> stop, leg", stop, leg, listType)
        this.selectedFeedbackStop = stop
        this.selectedFeedbackLeg = leg
        this.selectedFeedbackRoute = leg.routeId
        this.stage = listType
      },
      selectRoute (legIndex, routeId) {
        console.log("selectRoute -> route", routeId, this.selectedFeedbackType)
        // this.selectedFeedbackRoute = route // this.transitLegs[route] && this.transitLegs[route].routeId
        this.selectedFeedbackRoute = routeId
        this.selectedTransitLegIndex = legIndex
        this.stage = this.selectedFeedbackType
      },
      async saveFeedback () {
        const data = {
          feedback_type: this.selectedFeedbackType,
          stop_id: this.selectedFeedbackStop ? this.selectedFeedbackStop.stopId : '',
          stop_lat: this.newStopLocation ? this.newStopLocation.split(",")[0] : '',
          stop_lng: this.newStopLocation ? this.newStopLocation.split(",")[1] : '' ,
          stop_name: this.newStopName || '',
          route_id: this.selectedFeedbackRoute || '',
          comments: this.comments,
          full_fare: this.fullTripFare,
          leg_fare: this.legFare,
          app_url: location.href
        }
        // if (this.selectedFeedbackType === 'incorrect-fare') {
        //   data.comments = "Correct fare: " + this.correctFare + " Comments: " + this.comments
        // }
        this.ajaxStatus = ''
        try {
          const response = await submitFeedback(data)
          this.ajaxStatus = 'success'
          console.log("saveFeedback -> response", response)
        } catch (error) {
          this.ajaxStatus = 'error'
          this.ajaxErrorMsg = error
          console.log("saveFeedback -> error", error)
        }
        setTimeout(() => this.ajaxStatus = '', 3000)
      },
      updateStopLocation (latLng) {
        console.log("***updateStopLocation -> latLng", latLng)
        this.selectedFeedbackStopNewLocation = latLng.lat.toFixed(5) + "," + latLng.lng.toFixed(5)
        this.newStopLocation = latLng.lat.toFixed(5) + "," + latLng.lng.toFixed(5)
      },
      newStopAdded (stop, routeId) {
        console.log("***newStopAdded -> stop, routeId", stop, routeId)
        this.newStopLocation = stop.lat.toFixed(5) + "," + stop.lng.toFixed(5)
      }
    },
    watch: {
      open (bool = false) {
        this.$store.commit('trip-planner/SET_OPEN_FEEDBACK', bool)
        // this.stage = 'start'
        this.stage = this.$store.state['trip-planner'].tripIndex > -1 ? 'start' : 'other'
        console.log("OPEN open -> this.$store.state['trip-planner'].tripIndex", this.$store.state['trip-planner'].tripIndex)
      },
      openForm (bool = false) {
        this.open = bool
      },
      // stage (newStage) {
      //   if (newStage === 'stop-location') {
      //   }
      // }
    },
    mounted () {
      this.legActive = this.transitLegs.map(x => Boolean(x))
      this.closeForm()
    },
    updated () {
      console.log("UPPPDATED", this.$store.state['trip-planner'].tripIndex)
    },
  }
</script>

<style lang="scss" scoped>
.stopid, .routeid {
  // font-style: italic;
  color: rgba(0,0,0,0.6);
}
.mini-map {
  height: 120px;
  border:solid 1px #eee;
}
</style>