import axios from 'axios'
// import { TRIP_FREQUENCIES } from './trip_frequencies'

const VUE_APP_API_BASE_URL = process.env.VUE_APP_API_BASE_URL || ''
console.log("*TCL: VUE_APP_API_BASE_URL", VUE_APP_API_BASE_URL)
console.log("*TCL: process.env.VUE_APP_API_BASE_URL", process.env.VUE_APP_API_BASE_URL)

export const fetchDirections = async (params, query) => {
  try {
    let url = VUE_APP_API_BASE_URL + '/api/directions/' + Object.values(params).join("/") + '?' + Object.keys(query).map(x => x + '=' + query[x]).join("&")
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}

export const fetchLegRoute = async (params, query) => {
  console.log("fetchLegRoute -> query", query)
  try {
    let url = VUE_APP_API_BASE_URL + `/api/leg-route/${params.startLat}/${params.startLng}/${params.endLat}/${params.endLng}`
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}

export const fetchNearestStops = async (minLat, minLng, maxLat, maxLng) => {
  try {
    let url = VUE_APP_API_BASE_URL + '/api/nearest-stops/' + minLat + '/' + minLng + '/' + maxLat + '/' + maxLng
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}

export const fetchStopDetails = async (stopId) => {
  try {
    let url = VUE_APP_API_BASE_URL + '/api/stop-details/' + stopId
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}

// https://itnext.io/the-most-common-mistake-of-writing-api-requests-4eda46dce03d
// https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
// https://medium.com/canariasjs/vue-api-calls-in-a-smart-way-8d521812c322
// https://developers.google.com/maps/documentation/directions/intro

export const fetchRailRealtime = async (station, lat, lng, crs = '') => {
  try {
    let url = VUE_APP_API_BASE_URL + '/api/darwin/' + crs + '?station=' + station + (lat ? '&lat=' + lat + '&lng=' + lng : '')
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}

export const fetchBusRealtime = async (stop, lat, lng, naptan = '') => {
  try {
    let url = VUE_APP_API_BASE_URL + '/api/nextbuses/' + naptan + '?stop=' + stop + (lat ? '&lat=' + lat + '&lng=' + lng : '')
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}

export const submitFeedback = async (data) => {
  try {
    let url = VUE_APP_API_BASE_URL + '/api/feedback/'
    const response = await axios.post(url, data);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}

export const signupUser = async (data) => {
  try {
    let url = VUE_APP_API_BASE_URL + '/api/signup/'
    const response = await axios.post(url, data);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}
// this needs to be run on back-end not on client side
// export const matchTripFrequency = (tripId ='', departTime = '00:00') => {
//   TRIP_FREQUENCIES.find(x => {
//     const freqTripId = x[0]
//     const freqStartTime = x[1]
//     const freqEndTime = x[2]
//     if (freqTripId === tripId) {

//     }
//   })
// }
