/* eslint-disable no-console */

import { register } from 'register-service-worker'

console.log("registerServiceWorker.js process.env.NODE_ENV", process.env.NODE_ENV)
// self.addEventListener('fetch',() => console.log("fetch"));

self.addEventListener('fetch', event => {
  console.log('Fetch event for ', event.request.url);
  event.respondWith(
    caches.match(event.request)
    .then(response => {
      if (response) {
        console.log('Found ', event.request.url, ' in cache');
        return response;
      }
      console.log('Network request for ', event.request.url);
      return fetch(event.request)

      // TODO 4 - Add fetched files to the cache

    }).catch(error => {
      console.log("SW error", error)

      // TODO 6 - Respond with custom offline page

    })
  );
});


if (process.env.NODE_ENV === 'production' || 1 === 1) {
  console.log("..........", process.env.BASE_URL)
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
