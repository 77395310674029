<template>
  <v-app>

    <v-dialog
      v-model="dialogInstallPrompt"
      style="z-index:9999"
      max-width="320">
      <v-card>
        <v-card-title class="headline">
          Install App
        </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <img
              src="@/assets/dummy-logo-E.png"
              class="logo"
              alt="LOGO"
              @click="goTo('/', true)">
            <div class="flex-grow-0 flex-shrink-0">
              <strong>LagosGO</strong>
              <div>lagosgo.route-masters.com</div>
            </div>
        </div>
        <p>
          Install <strong>Lagos GO</strong> as a <strong>progressive web app</strong> for a better user experience. It won't take up space on your phone and loads faster!
        </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            outlined
            @click="dismiss"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green white--text darken-1"
            @click="install"
          >
            Install
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main v-if="$route && !isAdminPage">
      <welcome-screen
        v-if="!signupCompleted"
        @signupHasCompleted="signupCompleted = true"></welcome-screen>
      <v-container
        fluid
        fill-height
        :class="{
          'app-blurred': !signupCompleted,
          'py-0': true,
          'px-0': true
        }">
        <v-row no-gutters style="height:100%" id="app-row">
          <v-col
            :order="($vuetify.breakpoint.smAndDown) ? 2 : 0"
            cols="12" xs="12" sm="12" md="4" lg="3" xl="3"
            ref="text-column"
            id="text-column"
            class="text-column AAwhite AAdarken-2 my-0"
            :class="{ 'shrink-text-height': mapOpen, 'grow-text-height': !mapOpen }"
            :style="{
              'position': 'relative',
              'height': ($vuetify.breakpoint.smAndDown) ? '65%' : '100%',
              'box-shadow': ($vuetify.breakpoint.smAndDown) ? '0 0 10px rgb(0, 0, 0, .3)' : null
            }">

            <!-- IE alert -->
            <v-alert
              style="border-radius: 0"
              dismissible
              color="red"
              type="error"
              v-if="isIE">
              This application is not optimised for Internet Explorer. Please consider viewing on a modern browser for a better experience.
            </v-alert>

            <!-- logo/title -->
            <v-toolbar
              srcA="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
              light
              height="56"
              flat>
              <v-toolbar-title class="d-flex">
                <div class="pwa" v-if="isPWA">PWA</div>
                <div class="pwa" style="left:48px;bottom:-6px" v-else>
                  <!-- <v-btn icon small color="primary lighten-2" @click="promptInstall">
                    <v-icon style="font-size:18px">mdi-plus-circle</v-icon>
                  </v-btn> -->
                </div>
                <img
                  src="@/assets/dummy-logo-E.png"
                  class="logo"
                  alt="LOGO"
                  @click="goTo('/', true)">
                <div class="logo-text">
                  <span class="ml-3 logo-subtitle">
                    Routemasters
                  </span>
                  <span class="beta">beta</span>
                  <div class="ml-3 logo-statement">
                    Trip Planner for Lagos
                    <div class="ml-1 git-tag">{{ gitTag }}</div>
                  </div>
                </div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <div v-if="$vuetify.breakpoint.smAndDown">
                <!-- <v-icon class="drag-icon" color="grey lighten-1" large>mdi-drag</v-icon> -->
                <div v-if="isPWA">
                  <v-icon
                    class="drag-icon"
                    color="grey lighten-1"
                    large>mdi-drag</v-icon>
                  <v-icon
                    class="drag-icon"
                    color="grey lighten-1"
                    style="margin-right: -10px;"
                    large>mdi-drag</v-icon>
                </div>
                <div v-else>
                  <v-btn
                    icon
                    small
                    color="black lighten-1"
                    :disabled="mobileLayout === 'form'"
                    @click="toggleMobileLayout('up')">
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    color="black lighten-1"
                    :disabled="mobileLayout === 'map'"
                    @click="toggleMobileLayout('down')">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-toolbar>

            <router-view />

          </v-col>
          <v-col
            :order="($vuetify.breakpoint.smAndDown) ? 1 : 0"
            cols="12" xs="12" sm="12" md="8" lg="9" xl="9"
            ref="map-column"
            id="map-column"
            :style="{
              'position': ($vuetify.breakpoint.smAndDown) ? 'relative' : null,
              'height': ($vuetify.breakpoint.smAndDown) ? '35%' : null}"
            class="map-column primary lighten-2 py-0 px-0">
            <main-map
              :mode="$route.params.mode"
              :resize-map="mapResize"
              :routeIndex="$route.params.routeIndex"></main-map>
          </v-col>
        </v-row>
        <feedback-form :openForm="openFeedback"></feedback-form>
        <div class="floating-feedback-button">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                title="Submit feedback"
                v-bind="attrs"
                v-on="on"
                @click="sendFeedback"
                fab
                dark
                small
                color="primary">
                <v-icon dark>
                  mdi-message-alert-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Submit feedback</span>
          </v-tooltip>
        </div>
        <div class="floating-pwa-install-button" v-if="!isPWA">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                title="Install App"
                v-bind="attrs"
                v-on="on"
                @click="promptInstall"
                fab
                dark
                small
                color="primary">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Install App</span>
          </v-tooltip>
        </div>
      </v-container>
    </v-main>
    <v-main v-else>
      <v-container fill-height class="grey lighten-3">
        <v-toolbar
          color="transparent"
          transparent
          height="56"
          flat>
          <v-toolbar-title class="d-flex">
            <img
              src="@/assets/dummy-logo-E.png"
              class="logo"
              alt="LOGO"
              @click="goTo('/', true)">
            <div class="logo-text">
              <span class="ml-3 logo-subtitle">
                Routemasters
              </span>
              <span class="beta">beta</span>
              <div class="ml-3 logo-statement">
                Trip Planner for Lagos
                <div class="ml-1 git-tag">{{ gitTag }}</div>
              </div>
            </div>
            <div class="ml-4 mt-5">ADMIN SITE</div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row style="min-height:100%">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <router-view name="admin" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>


  </v-app>
</template>

<script>
import Cookies from "js-cookie";
import MainMap from './components/MainMap.vue'
import FeedbackForm from './components/FeedbackForm.vue'
import WelcomeScreen from './components/WelcomeScreen.vue'
import { isPWA } from './helpers/utils.js'
import { tag } from '../git-version.json'

export default {
  name: 'App',
  components: {
    MainMap, FeedbackForm, WelcomeScreen
  },
  data: () => ({
    signupCompleted: false,
    isAdminPage: false,
    deferredPrompt: null,
    dialogInstallPrompt: false,
    gitTag: tag,
    mobileLayout: "split",
    mapOpen: false,
    mapResize: false,
    isPWA: isPWA(),
    isIE: navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > -1
  }),
  computed: {
    mapLat () {
      if (!this.$store.map) {
        return 0
      }
      return this.$store.map.state.lat
    },
    openFeedback () {
      return this.$store.state['trip-planner'].openFeedback
    },
    isAdminPageNA () {
      if (location.href.indexOf("admin/") > -1) {
        return true
      }
      return false
    }
  },
  methods: {
    goTo (url) {
      location.href = url
    },
    async dismiss () {
      Cookies.set("add-to-home-screen", null, { expires: 5 }); // expires after 5 days
      this.deferredPrompt = null;
      this.dialogInstallPrompt = false
    },
    async install () {
      this.deferredPrompt.prompt();
      this.dialogInstallPrompt = false
    },
    promptInstall () {
      this.dialogInstallPrompt = true
    },
    initPWAtoggle () {
      const textEl = document.getElementById("text-column");
      const mapEl = document.getElementById("map-column");
      textEl.addEventListener("touchstart", handleStart, false);
      textEl.addEventListener("touchend", handleEnd, false);
      textEl.addEventListener("touchcancel", handleCancel, false);
      textEl.addEventListener("touchmove", handleMove, false);
      // textEl.addEventListener("scroll", scroller, false);

      let thisRef = this
      let clientY = 0
      let mapHeightPercent = 35
      let mapHeight = (window.innerHeight * (mapHeightPercent/100))
      // let noScroll = false
      let sliderActive = false

      function handleStart (e) {
        sliderActive = (e.touches[0].clientY - textEl.offsetTop) < 90
        console.log("handleStart -> (e)", e.touches[0].clientY, textEl.offsetTop)
        thisRef.mapResize = false
        if (sliderActive) {
          textEl.style.overflow = 'hidden'
        }
        // clientY = e.touches[0].clientY;
      }
      function handleEnd () {
        thisRef.mapResize = true
        clientY = 0
        if (parseInt(mapEl.style.height.split("px").join("")) < 70) {
          mapEl.style.height = 0;
        }
        textEl.style.overflow = 'auto'
      }
      function handleCancel () {
        clientY = 0
      }
      function handleMove (e) {
        if (!sliderActive) {
          return
        }
        let deltaY;
        deltaY = clientY ? e.changedTouches[0].clientY - clientY : 0;
        mapHeight = mapHeight + parseInt(deltaY)
        clientY = e.touches[0].clientY;
        mapEl.style.height = mapHeight + "px" //'calc(' + parseInt(deltaY) + 'px - 35%)'
      }
    },
    initMobileToggle () {

    },
    toggleMobileLayout (action = "up") {
      const textEl = document.getElementById("text-column");
      const mapEl = document.getElementById("map-column");
      if (action === "up") {
        if (this.mobileLayout === "split") {
          this.mobileLayout = "form"
        } else if (this.mobileLayout === "map") {
          this.mobileLayout = "split"
        }
      } else if (action === "down") {
        if (this.mobileLayout === "split") {
          this.mobileLayout = "map"
        } else if (this.mobileLayout === "form") {
          this.mobileLayout = "split"
        }
      }
      if (this.mobileLayout === "split") {
        mapEl.style.height = '35vh';
        textEl.style.height = '65vh';
      } else if (this.mobileLayout === "form") {
        textEl.style.height = 'calc(100vh - 1px)';
        mapEl.style.height = '1px';
      } else if (this.mobileLayout === "map") {
        mapEl.style.height = 'calc(100vh - 106px)';
        textEl.style.height = '106px';
      }
      this.mapResize = false
      setTimeout(() => this.mapResize = true, 200)
      // this.mobileLayout = action // map or split
      // if (mapEl.style.height < 20 && action === "up") {
      //   return
      // }
      // if (textEl.style.height < 120){
      //   if (action === "down") {
      //     return
      //   }
      //   mapEl.style.height = 35%;
      //   textEl.style.height = 65%;
      // }
      //   // } else if (textEl.style.height < 120) {
      // if (action === "up") {
      //   mapEl.style.height = 0;
      //   textEl.style.overflow = 'auto'
      // }
    },
    sendFeedback () {
      // let feedbackOpen = this.$store.state['trip-planner'].openFeedback
      // this.$store.state['trip-planner'].tripIndex
      console.log("sendFeedback -> this.$store.state['trip-planner'].tripIndex", this.$store.state['trip-planner'].tripIndex)
      this.$store.commit('trip-planner/SET_OPEN_FEEDBACK', true)
    },
    checkSignupScreen () {
      console.log("cccc", Cookies.get("signup-complete"))
      if (Cookies.get("signup-complete") === undefined) {
        this.signupCompleted = false
        return
      }
      this.signupCompleted = true
      return
    }
  },
  watch: {
    mapLat () {
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.mobileLayout === "form") {
          this.toggleMobileLayout('down')
        }
      }
    }
  },
  mounted () {
    console.log("mounted -> gitTag", this.gitTag, tag)
    console.log("mounted -> $route.name", this.$route.name)
    this.checkSignupScreen()
    // if (this.$route.name.substr(0,5) === 'admin') {
    // setTimeout(() => this.dialogInstallPrompt = true, 10000)
    if (!this.$vuetify.breakpoint.smAndDown) {
      return
    }
    // only for mobile/PWA
    if (isPWA()) {
      this.initPWAtoggle()
      if (!this.$route.query.pwa) {
        this.$router.push("/?pwa=1")
        return
      }
      return
    }
    this.initMobileToggle()
  },
  created () {
    if (location.href.indexOf('admin') > -1) {
      this.isAdminPage = true
    }
    window.addEventListener("beforeinstallprompt", e => {
      console.log("created -> beforeinstallprompt")
      e.preventDefault();
      // Stash the event so it can be triggered later.
      if (Cookies.get("add-to-home-screen") === undefined) {
        // setTimeout(() => this.deferredPrompt = e, 5000);
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      console.log("created -> appinstalled")
      this.deferredPrompt = null;
    });
  },
};
</script>
<style lang="scss" src="./sass/_styles.scss">
  // @import url('./sass/_styles.scss');
  // NOTE: import does not compile sass but style src does
</style>
<style lang="scss" soped>
.logo {
  max-height: 55px;
  cursor: pointer;
}
.logo-subtitle {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.beta {
  font-size: 10px;
  opacity: 0.5;
}
.logo-statement {
  font-size: 12px;
  // font-style: italic;
  line-height: 10px;
  // margin-left: 16px;
  opacity: 0.7;
  display: block;
}
.logo-text {
  margin-top: 5px;
}

.drag-icon {
  margin-left: -18px;
}
.pwa {
  position: absolute;
  font-size: 9px;
  bottom: 0;
}
.git-tag {
  display: inline-block;
  font-size:11px;
}
.floating-feedback-button, .floating-pwa-install-button {
  position: absolute;
  right: 0;
  z-index: 999;
}
.floating-feedback-button {
  bottom: 25px;
}
.floating-pwa-install-button {
  bottom: 75px;
}
@media (max-width: 720px) {
  .floating-feedback-button {
    bottom: 65px;
  }
  .floating-pwa-install-button {
    bottom: 115px;
  }
}

toolbar {
  background: repeating-linear-gradient(45deg , transparent, transparent 12px, #eee 12px, #eee calc(2 * 12px));
}
.app-blurred {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}
</style>