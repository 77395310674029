import { fetchRailRealtime, fetchBusRealtime } from '../../api'

let intervalFetchRealtime

const initialState = () => ({
  bus: {},
  rail: {},
  initialised: false
})
const state = initialState()

const mutations = {
  ADD_REALTIME_BUS (state, payload) {
    let stop = payload.stop
    let lat = payload.lat
    let lng = payload.lng
    if (!state.bus[stop]) {
      state.bus[stop] = { naptan: '', lat: lat, lng: lng, data: [] }
    }    
  },
  ADD_REALTIME_RAIL (state, payload) {
    let station = payload.station
    let lat = payload.lat
    let lng = payload.lng    
    if (!state.rail[station]) {
      state.rail[station] = { crs: '', lat: lat, lng: lng, data: [] }
    }
  },
  UPDATE_RAIL_REALTIME (state, payload) {
    state.rail[payload.name].crs = payload.crs
    state.rail[payload.name].data = payload.data
    state.rail = { ...state.rail } // force reactivity
  },
  UPDATE_BUS_REALTIME (state, payload) {
    state.bus[payload.name].naptan = payload.naptan
    state.bus[payload.name].data = payload.data
    state.bus = { ...state.bus } // force reactivity
  },
  SET_INITIALISED (state, bool = false) {
    state.initialised = bool
  },
  CLEAR_REALTIME (state) {
    state.bus = {}
    state.rail = {}
    state.initialised = false
    if (intervalFetchRealtime) {
      clearInterval(intervalFetchRealtime)
    }
  }
}
const actions = {
  initRealtime ({ dispatch , commit }) {
    dispatch('getRealtime')
    if (!intervalFetchRealtime) {
      commit('SET_INITIALISED', true)      
      intervalFetchRealtime = setInterval(() => dispatch('getRealtime'), 1000*30) // fetch every 30 seconds
    }
  },
  getRealtime ({ state, commit }) {
    if (!document.hasFocus()) {
      console.log("Browser not in focus so do not fetch real-time")
      return
    }
    let railPromisesArr = []
    let busPromisesArr = []
    Object.keys(state.bus).forEach(stop => {
      busPromisesArr.push(fetchBusRealtime(stop, state.bus[stop].lat, state.bus[stop].lng, state.bus[stop].naptan))
    })
    Object.keys(state.rail).forEach(station => {
      railPromisesArr.push(fetchRailRealtime(station, state.rail[station].lat, state.rail[station].lng, state.rail[station].crs))
    })    
    Promise.all(railPromisesArr)
      .then(response => {
        Object.keys(state.rail).forEach((station, index) => {
          if (response.length) {
            commit('UPDATE_RAIL_REALTIME', { name: station, crs: response[index].crs, data: response[index].trainServices })
          }
        })
      })
    Promise.all(busPromisesArr)
      .then(response => {
        Object.keys(state.bus).forEach((stop, index) => {
          if (response.length) {
            commit('UPDATE_BUS_REALTIME', { name: stop, naptan: response[index].naptan, data: response[index].MonitoredStopVisit })
          }
        })
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}