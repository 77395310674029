import Vue from 'vue'
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

// https://gist.github.com/varmais/74586ec1854fe288d393

let watcherId;

const initialState = () => ({
  lat: 0,
  lng: 0,
  accuracy: 0,
  altitude: 0,
  altitudeAccuracy: 0,
  speed: 0,
  heading: '',
  enabled: false,
  timestamp: Date.now(),
  error: '',
  waiting: false
})
const state = initialState()

const mutations = {
  SET_MY_LOCATION (state, payload) {
    state.lat = payload.lat
    state.lng = payload.lng
    state.timestamp = Date.now()
    state.accuracy = payload.accuracy
    state.altitude = payload.altitude
    state.altitudeAccuracy = payload.altitudeAccuracy
    state.speed = payload.speed
    state.heading = payload.heading
  },
  SET_ENABLED (state, bool = false) {
    state.enabled = bool
  },
  SET_ERROR (state, error = {}) {
    state.error = error
  },
  SET_WATING (state, bool = false) {
    state.waiting = bool
  },
}
const actions = {
  watchLocation ({ commit }) {
    if (watcherId) {
      return watcherId
      // navigator.geolocation.clearWatch(watcherId);
    }
    commit('SET_WATING', true)
    watcherId = Vue.prototype.$watchLocation()
      .then(coordinates => {
        commit('SET_MY_LOCATION', coordinates)
        commit('SET_ENABLED', true)
        commit('SET_WATING', false)
        return coordinates
      }).catch(err => {
        console.log(" watchLocation -> err", err)
        commit('SET_ENABLED', false)
        commit('SET_ERROR', err)
        commit('SET_WATING', false)
        // return err
        throw new Error(err)
      }) 
    return watcherId
  }  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}