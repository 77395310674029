const getStartLocation = () => ({ lat: 6.45988, lng: 3.37705 })

const getStartZoom = () => 12

const initialState = () => ({
  startLocation: getStartLocation(),
  startZoom: getStartZoom(),
  currentLocation: getStartLocation(),
  currentZoom: getStartZoom(),
  lat: 0,
  lng: 0,
  pan: false,
  popupActive: false,
  popupContent: '',
  popupLatLng: getStartLocation(),
  previewActive: false,
  previewLat: 0,
  previewLng: 0,
  mapObject: null
})
const state = initialState()

const mutations = {
  SET_POPUP (state, obj) {
    state.popupActive = obj.active
    state.popupContent = obj.content || ''
    state.popupLatLng = obj.latLng || state.popupLatLng
  },
  SET_MAP_LATLNG (state, payload) {
    state.lat = payload.lat
    state.lng = payload.lng
  },
  SET_PAN (state, bool = false) {
    state.pan = bool
  },
  SET_PREVIEW_LATLNG (state, payload) {
    state.previewLat = payload.lat
    state.previewLng = payload.lng
  },
  SET_PREVIEW_ACTIVE (state, bool = false) {
    state.previewActive = bool
  }
}

const actions = {
  // async planJourney ({ commit }, payload) {
  //   commit('SET_ERROR', '')
  //   try {
  //     let results = await fetchDirections(payload)
  //     commit('SET_RESULTS', results)
  //   } catch (e) {
  //     commit('SET_ERROR', e.toString())
  //   }
  // },
  // toggleMapLayer ({ commit }, payload, item, index, bool) {
  //   let { index, active } = payload
  //   commit('UPDATE_MAP_LAYERS', { index, active: bool })
  //   if (item.text === 'Bicycling Layer') {
  //     if (!bikeLayer) {
  //       bikeLayer = new google.maps.BicyclingLayer();
  //     }
  //     bikeLayer.setMap(bool ? this.mapObject : null);
  //   } else if (item.text === 'Traffic Layer') {
  //     if (!trafficLayer) {
  //     trafficLayer = new google.maps.TrafficLayer();
  //     }
  //     trafficLayer.setMap(bool ? this.mapObject : null);
  //   }
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}