import Vue from 'vue'
import { fetchDirections } from '../../api'

const initialState = () => ({
  inputs: {
    origin: {
      name: '',
      lat : '',
      lng : '',
      type: '',
    },
    destination: {
      name: '',
      lat : '',
      lng : '',
      type: '',
    },
    when: {
      type: 'now',
      date: (new Date()).toISOString().substr(0,10), // iso8601
      time: (new Date()).toLocaleTimeString("en-US", {timeZone: "Africa/Lagos"}).substr(0,5), // iso8601
      // time_zone: settings.DATETIME.TIME_ZONE,
    },
    modes: ['walking', 'transit'],
    options: {
      maxTransfers: 3,
      maxWalkDistance: 1000,
      minTransferTime: 100,
      numItineraries: 3,
    },
    maxWalkDistanceList: [
      { label: '5 min', value: 0.4 },
      { label: '10 min', value: 0.8 },
      { label: '15 min', value: 1.2 },
      { label: '20 min', value: 1.6 },
      { label: '30 min', value: 2.4 }
    ]
  },
  stage: 'inputs',
  openFeedback: false,
  results: [],
  legRoutes: {},
  nearestStops: [],
  tripIndex: -1,
  loading: false,
  responseReceived: false,
  error: ''
})
const state = initialState()

const mutations = {
  SET_INPUTS_ORIGIN (state, payload) {
    state.inputs.origin = payload //Object.assign({}, state.inputs.origin, payload)
  },
  SET_INPUTS_DESTINATION (state, payload) {
    state.inputs.destination = payload
  },
  SET_INPUTS_WHEN_DATE (state, date) {
    state.inputs.when = { ...state.inputs.when, date}
  },
  SET_INPUTS_WHEN_TIME (state, time) {
    state.inputs.when = { ...state.inputs.when, time}
  },
  SET_INPUTS_WHEN_TYPE (state, type) {
    // state.inputs.when.type = type
    state.inputs.when = { ...state.inputs.when, type}
  },
  SET_INPUTS_MODES (state, modes = []) {
    state.inputs.modes = modes
  },
  SET_INPUTS_OPTIONS (state, options = {}) {
    state.inputs.options = { ...state.inputs.options, ...options }
  },
  SET_RESULTS (state, results = {}) {
    console.log("SET_RESULTS -> results", results)
    state.results = results
  },
  SET_LEG_ROUTES (state, payload) {
    Vue.set(state.legRoutes, payload.legIndex, payload.points)
    // state.legRoutes[payload.legIndex] = payload.points
  },
  RESET_LEG_ROUTES (state) {
    state.legRoutes = {}
  },
  SET_NEAREST_STOPS (state, stops = []) {
    state.nearestStops = stops
  },
  SET_STAGE (state, stage = 'inputs') {
    state.stage = stage
  },
  SET_LOADING (state, bool = false) {
    state.loading = bool
  },
  SET_RESPONSE_RECEIVED (state, bool = false) {
    state.responseReceived = bool
  },
  SET_TRIP_INDEX (state, index = 0) {
    state.tripIndex = index
  },
  SET_ERROR (state, error = '') {
    state.error = error
  },
  SET_OPEN_FEEDBACK (state, bool = false) {
    console.log("SET_OPEN_FEEDBACK -> bool", bool)
    state.openFeedback = bool
  },
}
const actions = {
  // https://stackoverflow.com/questions/35612428/call-async-await-functions-in-parallel
  async planJourney ({ state, commit }, payload) {
    let params = {
      startName: state.inputs.origin.name,
      startLat: Number(state.inputs.origin.lat).toFixed(6),
      startLng: Number(state.inputs.origin.lng).toFixed(6),
      finishName: state.inputs.destination.name,
      finishLat: Number(state.inputs.destination.lat).toFixed(6),
      finishLng: Number(state.inputs.destination.lng).toFixed(6),
      date: state.inputs.when.date,
      time: state.inputs.when.time,
      deparr: state.inputs.when.type,
      modes: state.inputs.modes.join(","),
    }
    let query = {
      maxTransfers: state.inputs.options.maxTransfers,
      maxWalkDistance: state.inputs.options.maxWalkDistance,
      minTransferTime: state.inputs.options.minTransferTime,
      numItineraries: state.inputs.options.numItineraries
    }
    commit('SET_ERROR', '')
    try {
      let results = await fetchDirections(payload || params, query)
      commit('SET_RESULTS', results.plan.itineraries)
    } catch (e) {
      commit('SET_ERROR', e.toString())
    }
  },
  // async fetchStops ({ state, commit }) {
  //   const results = await fetchNearestStops()
  //   console.log("fetchStops -> results", results)
  // }
}

const getters = {
  inputsOrigValid: state => {
    let orig = state.inputs.origin
    return Boolean(orig.name && orig.lat && orig.lng)
  },
  inputsDestValid: state => {
    let dest = state.inputs.destination
    return Boolean(dest.name && dest.lat && dest.lng)
  },
  inputsWhenValid: state => {
    let when = state.inputs.when
    return Boolean(when.type && when.date && when.time)
  },
  inputsModesValid: state => {
    return Boolean(state.inputs.modes.length)
  },
  inputsValid: (state, getters) => {
    return (getters.inputsOrigValid &&
           getters.inputsDestValid &&
           getters.inputsWhenValid &&
           getters.inputsModesValid)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}