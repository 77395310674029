<template>
  <div class="map-container">
    <l-map
      v-if="showMap"
      id="main-map"
      ref="main-map"
      class="main-map"
      :zoom="startZoom"
      :center="startLocation"
      :options="{ zoomControl: false }"
      @update:zoom="onZoomChange"
      @click="clickOnMap">

      <l-control-scale
        position="bottomleft"
        :imperial="true"
        :metric="false">
      </l-control-scale>

      <l-control-zoom position="topright"></l-control-zoom>

      <l-tile-layer :url="tileUrl"></l-tile-layer>

      <l-polyline
        v-for="(leg, i) in tripLegPolylines"
        :key="'pl-' + i"
        :lat-lngs="leg.latLngs"
        :weight="10"
        :opacity="leg.walkleg ? 1 : 0.6"
        :dashArray="leg.walkleg ? '1, 10' : ''"
        :color="leg.walkleg ? '#555' : 'red'"></l-polyline>

      <l-circle-marker
        v-for="(leg, i) in tripLegs"
        :key="'cm-' + i"
        :lat-lng="[leg.lat, leg.lng]"
        :radius="5"
        :color="'#000'"
        :fillColor="'#fff'"
        :fillOpacity="0.9"
      />

      <!-- <l-circle-marker
        v-for="(stop, i) in tripIntermediateLegs"
        :key="'cim-' + i"
        :lat-lng="[stop.lat, stop.lon]"
        :radius="3"
        :color="'#ff0000'"
        :fillColor="'#ff0000'"
        :fillOpacity="0.7"
      /> -->

      <l-circle-marker
        v-for="(stop, i) in nearestStops"
        :key="'stop-' + i"
        :lat-lng="[stop.lat, stop.lon]"
        :options="{ title: stop.name }"
        @mouseup="getStopDetails(stop.id, $event)"
        :radius="5"
        :weight="1"
        :color="'#111'"
        :fillColor="'#ee1122'"
        :fillOpacity="0.9"
      />

      <!-- GPS icon -->
      <l-circle-marker
        v-if="geolocation.lat"
        :lat-lng="[geolocation.lat, geolocation.lng]"
        :radius="8"
        :options="{
          opacity: 1,
          fillOpacity: 1,
          weight: 1
        }"
        :opacity="1"
        color="#fff"
        fillColor="#4286F5"
      />
      <l-circle
        v-if="geolocation.lat"
        :lat-lng="[geolocation.lat, geolocation.lng]"
        :radius="geoaccuracy"
        :options="{
          opacity: 0.2
        }"
        color="#4286F5"
      />


      <!-- <l-circle-marker
        v-if="origin.lat"
        :lat-lng="[origin.lat, origin.lng]"
        :radius="6"
        color="green"
      />
      <l-circle-marker
        v-if="destination.lat"
        :lat-lng="[destination.lat, destination.lng]"
        :radius="6"
        color="red"
      /> -->

      <!-- Orig/Dest markers -->
      <l-marker
        v-if="origin.lat"
        @dragend="origSelected"
        :draggable="true"
        :icon="markerIconStart"
        :lat-lng="[origin.lat, origin.lng]"
      />
      <l-marker
        v-if="destination.lat"
        @dragend="destSelected"
        :draggable="true"
        :icon="markerIconEnd"
        :lat-lng="[destination.lat, destination.lng]"
      />

    </l-map>

  </div>
</template>

<script>
// import { getCenterOfBounds } from 'geolib';
import L from 'leaflet';
import { latLngBounds } from "leaflet";
import polyline from  'google-polyline'
import { LMap, LTileLayer, LControlScale, LControlZoom, LMarker, LCircleMarker, LCircle, LPolyline } from 'vue2-leaflet';
import { mapState, mapMutations } from 'vuex';
import { fetchNearestStops, fetchStopDetails } from '../api'

let mapObjectRef, popup

export default {
  components: {
    LMap, LTileLayer, LControlScale, LControlZoom, LMarker, LCircleMarker, LCircle, LPolyline
  },
  props: {
    resizeMap: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showMap: false,
      tileUrl: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      mapboxTileUrl: 'https://api.mapbox.com/styles/v1/steerdigital/ckjsvezzq80vw19qsrd1yao76/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3RlZXJkaWdpdGFsIiwiYSI6ImNrMm9sM3FjMjA1aGozYnRkMnBxbjdseTMifQ.Z5axBJPA-6kKYhELu1ZhPQ',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      // withPopup: latLng(43.641583,-79.398173),
      nearestStops: [],
      currentZoom: 12,
      markerIconOptions: {
        iconUrl: '/img/icons/flaticon/maps-and-flags.svg',
        iconSize: [32, 55],
        iconAnchor: [16, 42],
        popupAnchor: [-3, -46]
      }
    }
  },
  computed: {
    ...mapState('map', {
      startLocation: state => state.startLocation,
      startZoom: state => state.startZoom,
      activeLat: state => state.lat,
      activeLng: state => state.lng,
      popupActive: state => state.popupActive,
      popupContent: state => state.popupContent,
      popupLatLng: state => state.popupLatLng
    }),
    ...mapState('trip-planner', {
      origin: state => state.inputs.origin,
      destination: state => state.inputs.destination,
      results: state => state.results,
      tripIndex: state => state.tripIndex,
      legRoutes: state => state.legRoutes,
      // mapSettings: state => state.map,
    }),
    ...mapState('geolocation', {
      geolocation: state => ({ lat: state.lat, lng: state.lng }),
      geoaccuracy: state => state.accuracy,
      geoheading: state => state.heading,
      geotimestamp: state => state.timestamp,
    }),
    markerIconStart () {
      return L.icon({ ...this.markerIconOptions, iconUrl: '/img/icons/flaticon/marker-start.svg' })
    },
    markerIconEnd () {
      return L.icon({ ...this.markerIconOptions, iconUrl: '/img/icons/flaticon/marker-end.svg' })
    },
    tripLegs () {
      if (this.tripIndex >= 0 && this.results.length) {
        return this.results[this.tripIndex].legs
          .filter((leg, index) => index > 0)
          .map((leg, index) => {
            return {
              lat: leg.from.lat,
              lng: leg.from.lon,
              index
            }
          })
      }
      return []
    },
    tripIntermediateLegs () {
      let arr = []
      if (this.tripIndex >= 0 && this.results.length) {
        arr = this.results[this.tripIndex].legs
          .filter((leg) => leg.intermediateStops)
          .map((leg) => {
            return leg.intermediateStops
          })
      }
      console.log("tripIntermediateLegs -> arr", arr)
      return [].concat.apply([], arr);
    },
    tripIsCar () {
      if (this.tripIndex >=0 && this.results.length) {
        return this.results[this.tripIndex].isCar
      }
      return false
    },
    tripLegPolylines () {
      if (this.tripIndex >=0 && this.results.length) {
        return this.results[this.tripIndex].legs
          .map((leg, index) => {
            console.log("tripLegPolylines", index, this.legRoutes[index])
            // console.log("****", polyline.decode(leg.legGeometry.points), index)
            return {
              latLngs: this.legRoutes[index] && leg.mode !== 'WALK'
                        ? polyline.decode(this.legRoutes[index])
                        : polyline.decode(leg.legGeometry.points),
              walkleg: leg.mode === 'WALK'
            }
          })
      }
      return []
      // return this.tripLegs.map((leg, i) => {
      //   // polyline.decode
      // })
    }
  },
  methods: {
    ...mapMutations('trip-planner', {
      setOrigin: 'SET_INPUTS_ORIGIN',
      setDestination: 'SET_INPUTS_DESTINATION',
    }),
    ...mapMutations('map', {
      setPopup: 'SET_POPUP'
    }),
    clickOnMap (event) {
      let place = {
        name: event.latlng.lat.toFixed(5) + "," + event.latlng.lng.toFixed(5),
        lat: event.latlng.lat,
        lng: event.latlng.lng
      }
      if (!this.origin.lat) {
        this.setOrigin(place)
      } else if (!this.destination.lat) {
        this.setDestination(place)
      }
    },
    onZoomChange () {
      this.currentZoom = mapObjectRef.getZoom()
      if (this.currentZoom <= 14) {
        this.nearestStops = []
      }
    },
    origSelected (place) {
      let ll = place.target.getLatLng() || place
      if (ll) {
        ll.name = ll.lat.toFixed(6) + "," + ll.lng.toFixed(6)
        this.setOrigin(ll)
      }
    },
    destSelected (place) {
      let ll = place.target.getLatLng() || place
      if (ll) {
        ll.name = ll.lat.toFixed(6) + "," + ll.lng.toFixed(6)
        this.setDestination(ll)
      }
    },
    dragMarkerHandler (inputType = 'orig', event) {
      let latLng = { lat: event.latLng.lat().toFixed(6), lng: event.latLng.lng().toFixed(6) }
      latLng.name = latLng.lat + "," + latLng.lng
      if (inputType === 'orig') {
        this.setOrigin(latLng)
      } else {
        this.setDestination(latLng)
      }
    },
    async fetchStops () {
      if (mapObjectRef.getZoom() <= 14 || this.tripIsCar) {
        return
      }
      const bounds = mapObjectRef.getBounds()
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      this.nearestStops = await fetchNearestStops(sw.lat, sw.lng, ne.lat, ne.lng)
    },
    async getStopDetails (stopId, e) {
      if (!popup) {
        popup = L.popup()
        popup.on("popupclose", () => {
          alert("*cliose popup")
          this.setPopup({ active: false })
        })
      }
      const res = await fetchStopDetails(stopId)
      const content = `<div style="max-height: 300px;overflow:auto">
        <div><strong>${res.id}</strong> ${res.name}</div>
        <p style="margin-top:3px">${res.desc || '-'}</p>
        ${res.routes.map(x => x.shortName + " - " + x.longName).join("<br>")}
      </div>
      `
      popup
        .setLatLng(e.latlng)
        .setContent(content)
        .openOn(mapObjectRef);
    }
  },
  watch: {
    activeLat (val, oldVal) {
      if (val === oldVal) {
        return
      }
      const zoom = (mapObjectRef.getZoom() > 15) ? mapObjectRef.getZoom() : 15
      mapObjectRef.setView([this.activeLat, this.activeLng], zoom, { animate: true, duration: 0.25 })
    },
    popupActive (bool = false) {
      if (!bool) {
        return
      }
      if (!popup) {
        popup = L.popup()
        popup.on("popupclose", () => this.setPopup({ active: false }))
      }
      popup
        .setLatLng(this.popupLatLng)
        .setContent(this.popupContent)
        .openOn(mapObjectRef);
    },
    resizeMap (bool) {
      console.log("resizeMap -> resizeMap", bool)
      if (bool) {
        mapObjectRef.invalidateSize()
      }
    },
    // tripLegs (legs) {
    //   if (this.tripIndex < 0) {
    //     return
    //   }
    //   console.log("tripLegs -> legs", legs, this.tripIndex)
    //   console.log("---", this.results[this.tripIndex].legs[0])
    //   console.log(" ")
    //   const bounds = latLngBounds()
    //   legs.forEach(leg => {
    //     bounds.extend([leg.lat, leg.lng])
    //   })
    //   console.log("tripLegs -> bounds", bounds)
    //   console.log("tripLegs -> mapObjectRef", mapObjectRef)
    //   if (mapObjectRef && legs.length) {
    //     mapObjectRef.fitBounds(bounds)
    //   }
    // },
    tripIndex () {
      if (this.tripIndex < 0) {
        return
      }
      const bounds = latLngBounds()
      this.tripLegs.forEach(leg => {
        bounds.extend([leg.lat, leg.lng])
      })
      if (this.tripIsCar) {
        let leg = this.results[this.tripIndex].legs[0]
        bounds.extend([leg.from.lat, leg.from.lon])
        bounds.extend([leg.to.lat, leg.to.lon])
      }
      if (mapObjectRef) {
        mapObjectRef.fitBounds(bounds)
      }
    }
    // tripIndex (no) {
    //   this.results[no]
    //   const bounds = latLngBounds([firstCoord, firstCoord])
    //   markerData.forEach((data) => {
    //     bounds.extend(data.latLng)
    //   })
    // }
  },
  mounted () {
    // console.log("mounted ->", )
    // this.showMap = true
    // console.log("mounted -> this.showMap", this.showMap)
    setTimeout(() => this.showMap = true, 100)
    setTimeout(() => {
      mapObjectRef = this.$refs['main-map'].mapObject
      mapObjectRef.on('moveend', () => {
          // console.log("***moveend", mapObjectRef.getBounds().getNorthEast());
          this.currentZoom = this.startZoom
          // this.fetchStops()
      });
      // this.setMapObject(this.$refs['main-map'].mapObject)
      // this.$refs['main-map'].mapObject
      // console.log("mounted -> this.$refs['main-map'].mapObject", this.$refs['main-map'].mapObject)
    }, 500)
  },
}
</script>

<style lang="scss" scoped>
.map-container {
  position:relative;
  height: 100%;
}
.main-map {
  height: 100%;
  width: 100%;
}
</style>