<template>
  <div>
    <v-list dense>
      <v-list-item-group
        color="primary">
        <div
          v-for="(leg, index) in legs "
          :key="leg.tripId + index">
          <v-subheader><v-icon>mdi-bus</v-icon> {{ leg.routeLongName }}</v-subheader>
          <v-list-item
            v-for="stop in [leg.from, ...leg.intermediateStops, leg.to]"
            :key="stop.stopId"
            @click="selectStop(stop, leg)">
            <v-list-item-icon style="margin-left:10px;margin-right:10px;">
              <v-icon small>mdi-octagon</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
              <span class="stopid">{{ stop.stopId }}</span> {{ stop.name }}
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="listType === 'missing-stop'"
                class="mt-3">
                <v-icon small color="primary">mdi-arrow-right</v-icon>
                Add new stop here
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
  export default {
    props: {
      legs: {
        type: Array,
        default: () => []
      },
      listType: {
        type: String,
        default: "stop-name" // stop-location, missing stop
      },
    },
    methods: {
      selectStop (stop, leg) {
        console.log("selectStop -> stop", stop, leg)
        this.$emit('selectstop', stop, leg, this.listType)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>