import { format, differenceInMinutes } from 'date-fns'
import humanizeDuration from 'humanize-duration'

export const getDates = function (startDate, endDate) {
  let dates = []
  let currentDate = startDate
  const addDays = function(days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};

export const getDatesFromNow = function (numDays) {
  let arr = new Array();
  let dateObj = new Date()
  let dateNum = dateObj.getDate()
  for (let day = 1; day < numDays; day++) {
    arr.push({
      text: (day === 1) ? 'Today' :
            (day === 2) ? 'Tomorrow' :
            format(dateObj, 'EEE do LLL'),
      value: format(dateObj, 'yyyy-MM-dd')
    });
    dateObj.setDate(dateNum + day);
  }

  return arr;
}

export const formatDuration = function (secs = 0) {
  return humanizeDuration(secs * 1000).split("hour").join("h").split("minutes").join("min")
}

export const localISOString = function () {
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  return (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
}

export const datesDiffInMinutes = function (dateLeft, dateRight) {
  return differenceInMinutes(Date.parse(dateLeft), Date.parse(dateRight))
}

// `min`, `max` and `date` are `Date` instances
export const dateIsBetween = (date, min, max) => (date.getTime() >= min.getTime() && date.getTime() <= max.getTime());

// convert array to object based on array properties for key and value
export const arrayToObject = (arr, keyField, valField) => Object.assign({}, ...arr.map(item => ({[item[keyField]]: item[valField]})))

export const isIE = navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > -1

export const formatAsInt = (no) => parseInt(no)

export const formatAsFloat = (no, dp = 2) => (no).toFixed(dp)

export const timeConverter = (UNIX_timestamp) => {
  const checkTime = (no) => no < 10 ? "0" + no : no
  const dt = new Date(UNIX_timestamp);
  const hour = checkTime(dt.getHours());
  const min = checkTime(dt.getMinutes());
  const time = hour + ':' + min;
  return time;
}

export const isPWA = () => {
  const mqStandAlone = '(display-mode: standalone)';
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    return true
  }
  return false
}
export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];